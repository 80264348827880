import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { newCountryComponent } from './@pages/country/new/new-country.component';
import { PagesComponent } from './@pages/pages.component';
import { NewProvinceComponent } from './@pages/province/new/new-province.component';
import { NewTownComponent } from './@pages/town/new/new-town.component';
import { ActivateUserComponent } from './activate-user/activate-user.component';
import { ChangePassComponent } from './change-pass/change-pass.component';
import { ForgottenComponent } from './forgotten-pass/forgotten-pass.component';
import { LoginGuard } from './guards/login.guard';
import { LogoutGuard } from './guards/logout.guard';
import { TokenGuard } from './guards/token.guard';
import { LoginComponent } from './login/login.component';
import { PagesNotFoundComponent } from './pages-not-found/pages-not-found.component';

const routes: Routes = [
  // Rutas de autenticación
  { path: 'login', component: LoginComponent, canActivate: [LogoutGuard] },
  { path: 'changePass', component: ChangePassComponent },
  { path: 'forgottenPass', component: ForgottenComponent },
  { path: 'activateUser', component: ActivateUserComponent },

  // Rutas principales que cargan módulos hijos en el outlet principal
  {
    path: '',
    component: PagesComponent,
    canActivate: [LoginGuard, TokenGuard],
    children: [
      { path: 'home', loadChildren: () => import('./@pages/home/home.module').then(m => m.HomeModule) },
      { path: 'countries', loadChildren: () => import("./@pages/country/country.module").then(m => m.ListModule) },
      { path: 'provinces', loadChildren: () => import('./@pages/province/province.module').then(m => m.ProvinceModule) },
      { path: 'towns', loadChildren: () => import('./@pages/town/list-towns.module').then(m => m.ListTownsModule) },
      { path: 'customers', loadChildren: () => import('./@pages/customer/customer.module').then(m => m.CustomerListModule) },
      { path: 'materials', loadChildren: () => import('./@pages/material/material.module').then(m => m.NewMaterialModule) },
      { path: 'budgets', loadChildren: () => import('./@pages/budget/budgets.module').then(m => m.BudgetsModule) },
      { path: 'delivery', loadChildren: () => import('./@pages/delivery/delivery.module').then(m => m.DeliveryListModule) },
      { path: 'invoices', loadChildren: () => import('./@pages/invoice/invoice.module').then(m => m.InvoiceModule) },
      { path: 'user', loadChildren: () => import('./@pages/user/user.module').then(m => m.UserModule) },
      { path: 'inventario', loadChildren: () => import('./@pages/inventario/inventario.module').then(m => m.InventarioModule) },
      { path: 'informes', loadChildren: () => import('./@pages/informes/informes.module').then(m => m.InformesModule) },
      { path: 'scan', loadChildren: () => import('./@pages/scan/scan.module').then(m => m.ScanModule) },
    ]
  },

    // Rutas auxiliares para el outlet "sidebar"
  { path: 'new-country', component: newCountryComponent, outlet: 'sidebar', canActivate: [LoginGuard] },
  { path: 'new-province', component: NewProvinceComponent, outlet: 'sidebar', canActivate: [LoginGuard] },
  { path: 'new-town', component: NewTownComponent, outlet: 'sidebar', canActivate: [LoginGuard] },


  // Redireccionamientos y manejo de páginas no encontradas
  { path: '', pathMatch: 'full', redirectTo: "home" },
  { path: '**', pathMatch: 'full', component: PagesNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
