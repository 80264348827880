import { Injectable } from "@angular/core";
import { MenuItem } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class ComunService {
  constructor() {}

  private itemsBreadcrumb: MenuItem[] = [];
  public home: MenuItem = {};

  setTitleBar(valor: MenuItem[], updateBreadcrumb: boolean = true) {
    if (updateBreadcrumb) {
      setTimeout(() => {
        this.itemsBreadcrumb = valor;
        this.home = { icon: "pi pi-home", routerLink: "/home" };
      }, 0);
    }
  }

  getTitleBar(): MenuItem[] {
    return this.itemsBreadcrumb;
  }
}
